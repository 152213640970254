<template>
  <!-- 资料库>新增资料 -->
  <div class="dict">
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      content="目标列表/新增目标"
    >
      <template slot="title">
        <i class="el-icon-arrow-left"></i>返回
      </template>
    </el-page-header>
    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :rules="rules"
      :label-width="labelWidth"
    >
      <el-form-item
        label="目标类型："
        prop="targetType"
      >
        <el-col :span="22">
          <el-select
            class="form-select-class"
            v-model="form.targetType"
            @change="changeTargetType"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in dictList.target_type"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="1">
          <el-button
            style="margin-left: 1.04167vw"
            icon="el-icon-plus"
            @click="createTargetType"
          >自定义</el-button>
        </el-col>
      </el-form-item>
      <el-form-item
        label="目标名称："
        prop="targetName"
      >
        <el-input
          v-model.trim="form.targetName"
          maxlength="20"
          onkeyup="input= input.replace(/[ ]/g,'')"
          placeholder="请输入目标名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="目标值："
        prop="targetValue"
      >
        <el-input
          type="number"
          v-model="form.targetValue"
          placeholder="请输入目标值"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="计量单位："
        prop="targetUnit"
      >
        <el-input
          v-model.trim="form.targetUnit"
          onkeyup="this.value=this.value.replace(/\s+/g,'')"
          placeholder="请输入计量单位"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="责任部门："
        prop="deptLabel"
      >
        <!-- <el-select v-model="form.deptLabel" placeholder="请选择">
          <el-option
            v-for="(item, index) in deptLabelList"
            :key="index"
            :label="item.name"
            :value="item.label"
          ></el-option>
        </el-select> -->
        <!-- { checkStrictly: true }  -->
        <el-cascader
          class="form-select-class"
          :options="deptLabelList"
          :props="deptPropsForm"
          v-model="form.deptLabel"
          :show-all-levels="false"
          @change="changeDept"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="截止日期："
        prop="endTime"
      >
        <el-date-picker
          class="form-select-class"
          v-model="form.endTime"
          type="datetime"
          placeholder="选择日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="日历图标："
        prop="iconPath"
      >
        <el-popover
          placement="right-start"
          width="494"
          popper-class="opIocn"
          @show="showIcon"
          trigger="click"
          v-model="dialogVisible"
        >
          <el-button
            slot="reference"
            type="text"
          >
            <span v-if="form.iconPath == ''">选择图标</span>
            <span v-else>更换图标</span>
          </el-button>
          <div
            slot="reference"
            v-if="form.iconPath"
            class="iconPathWrap"
          >
            <img
              :src="form.iconPath.scheduleIcon"
              alt=""
            >
          </div>
          <div>
            <div class="iconTitle">
              <span>图标库</span>
              <span
                class="el-icon-close"
                @click="dialogVisible = false"
              ></span>
            </div>
            <div class="dialogDiv">
              <div
                :class="iconPathInfo.id == item.id ?'iconPathWrap active':'iconPathWrap'"
                @click="chooseIconPath(item)"
                v-for="item in iconPathList"
                :key="item.id"
              >
                <img
                  :src="item.scheduleIcon"
                  alt=""
                >
              </div>
            </div>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button
                type="primary"
                @click="chooseIconPathBtn"
              >确 定</el-button>
            </span>
          </div>
        </el-popover>
      </el-form-item>

      <el-form-item
        label="描述："
        prop="targetDescript"
      >
        <el-input
          type="textarea"
          rows="4"
          v-model="form.targetDescript"
          placeholder="请输入描述"
        ></el-input>
      </el-form-item>
      <el-divider content-position="left">目标分解</el-divider>
      <el-form-item
        label="分解维度："
        prop="type"
      >
        <el-radio-group
          @change="changeDimension"
          v-model="form.type"
        >
          <el-radio
            v-for="(item,index) in dictList.target_part_type"
            :disabled="(form.targetType == 1 || form.targetType == 6) && index<2"
            :key="index"
            :label="item.value"
            :value="item.value"
          >{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="createResolutionList"
        >新增</el-button>
      </el-form-item>
      <el-form-item>
        <table border="1">
          <tr>
            <th width="25%">分解对象</th>
            <th width="25%">目标值(总目标:{{form.targetValue}},剩余未分配:<span
                class="activeColor"
              >{{unassigned}}</span>)</th>
            <th width="25%">指定岗位/人</th>
            <th width="25%">操作</th>
          </tr>
          <tr
            v-for="(item,index) in childList"
            :key="index"
          >
            <td>
              <!-- 分解对象，园校 -->
              <el-cascader
                filterable
                v-if="form.type == 3"
                v-model="item.companyLabel"
                @change="changeSchool(item)"
                :disabled="item.isSave == 1"
                :options="headquartersObjList"
                :props="optionProps"
              ></el-cascader>
              <!-- 分解对象，分公司 -->
              <el-select
                filterable
                v-else-if="form.type == 2"
                @change="changeCompany(item)"
                :disabled="item.isSave == 1"
                v-model="item.companyLabel"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in companyList"
                  :key="index"
                  :label="item.name"
                  :value="item.label"
                ></el-option>
              </el-select>
              <!-- 分解对象，总部 -->
              <el-cascader
                filterable
                v-else
                :disabled="item.isSave == 1"
                :options="deptLabelList"
                :props="deptPropsForm"
                v-model="item.companyLabel"
                :show-all-levels="false"
                @change="changeDept(item)"
                clearable
              ></el-cascader>
            </td>
            <td>
              <!-- 目标值 -->
              <!-- -->
              <el-input
                :disabled="item.isSave == 1"
                v-model.trim="item.targetValue"
                type="number"
                placeholder="请输入"
                @blur="onInputBlur"
              ></el-input>
            </td>
            <!-- 指定岗位/人 -->
            <td>
              <el-cascader
                :options="item.userPostList"
                :disabled="item.isSave == 1"
                :props="deptProps"
                v-model="item.dutyUser"
                :loading="userLoading"
                :show-all-levels="false"
                @change="changeUserPost(item)"
                clearable
              ></el-cascader>
            </td>
            <td>
              <el-button
                v-if="item.isSave == 0"
                type="text"
                size="small"
                @click="editList(1,item)"
              >保存</el-button>
              <el-button
                v-else
                type="text"
                size="small"
                @click="editList(2,item)"
              >编辑</el-button>
              <el-button
                type="text"
                size="small"
                @click="delList(index)"
              >删除</el-button>
            </td>
          </tr>
        </table>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="goBack"
          class="reset"
        >返回</el-button>
        <el-button
          type="primary"
          :disabled="uploading"
          :loading="uploading"
          @click="saveAndPublishTarget('ruleForm')"
        >发布</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data () {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      // if (numberAuth.test(valueString)) {
      //   callback(new Error("目标值只支持大于0数字"));
      // } else if (value == 0) {
      //   callback(new Error("目标值只支持大于0数字"));
      // } else if (valueString.length > 9) {
      //   callback(new Error("目标值长度不能超过9位"));
      // } else {
      //   callback();
      // }
      if (valueString.length > 9) {
        callback(new Error("目标值长度不能超过9位"));
      }else {
        callback();
      }
    };
    var checkChildList = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      if (this.childList.length == 0) {
        callback(new Error("请添加分解数据"));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate (time) {
          let _now = Date.now();
          return time.getTime() < _now;
        }
      },
      form: {
        targetType: "",
        targetName: "",
        targetValue: 1,
        targetUnit: "",
        deptLabel: "",
        endTime: "",
        targetDescript: "",
        type: '1',
        level: 1,
        iconPath: ''
      },
      childList: [],
      uploading: false,
      targeTypeList: [],
      deptLabelList: [
        // {label:'test1',value:1},
        // {label:'test2',value:2},
      ],
      uploadList: [],
      rules: {
        targetType: [
          { required: true, message: "请选择目标类型", trigger: "blur" },
        ],
        targetName: [
          { required: true, message: "请输入目标名称", trigger: "blur" },
        ],
        targetValue: [
          { required: true, message: "请输入目标值", trigger: "blur" },
          { validator: checkNum, trigger: 'blur' }
          // checkNum
        ],
        targetUnit: [
          { required: true, message: "请输入计量单位", trigger: "blur" },
        ],
        deptLabel: [
          { required: true, message: "请选择责任部门", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择分解维渡", trigger: "blur" },
        { validator: checkChildList, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: "请选择截止日期", trigger: "blur" },
        ],
        iconPath: [
          { required: true, message: "请选择日历图标", trigger: "blur" },
        ],
        targetDescript: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
      },
      dictList: {
        target_type: [],
        target_part_type: []
      },
      optionProps: {
        value: 'label',
        label: 'name',
        children: 'child'
      },
      headquartersObjList: [],
      companyList: [],
      companysList: [],
      deptProps: {
        checkStrictly: false,
        label: 'name',
        value: 'label',
        children: 'child',
        multiple: true
      },
      deptPropsForm: {
        checkStrictly: true,
        label: 'name',
        value: 'label',
        children: 'child',
      },
      userPostProps: {
        checkStrictly: true,
        label: 'postName',
        value: 'label',
        children: 'child',
      },
      userLoading: false,
      schoolLength: 0,

      labelWidth: '120px',
      dialogVisible: false,
      iconPathList: [],
      iconPathInfo: {},
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
    unassigned: function () {
      let numb = 0
      if (this.childList) {
        this.childList.forEach(e => {
          if (e.isSave) {
            numb += Number(e.targetValue)
          }
        })
      }
      return parseFloat((this.form.targetValue - numb).toFixed(4));
    }
  },
  watch: {
    permissionButtonList: {
      handler (val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler (val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created () {
    console.log()
    this.queryDict()
    this.getDepartList()
    this.initIconData()
  },
  mounted () {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if (window.innerWidth < 1300) {
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    //目标值需要大于0
    onInputBlur (e) {
      console.log(e.target.value, '目标值大于0不')
      // if (e.target.value <= 0) {
      //   this.$message.error('目标值需要大于0');
      // }
    },

    createResolutionList () {
      this.childList.push({
        isSave: 0,
        dutyUser: [
          {
            postLabel: '',
            userLabel: []
          },
          {
            postLabel: '',
            userLabel: []
          },
        ], //责任人
        targetValue: '', //目标值
        type: this.form.type, //分解维度类型
        companyLabel: '', //所属分公司
        campusLabel: '',//所属园区
        level: 1,
        dutyUserName: [],
        userPostList: [],
        index: this.childList.length
      })
      // console.log(this.childList)
    },
    // 修改总部
    changeDept (val) {
      console.log(val)
      if (val.companyLabel) {
        val.dutyUser = ''
        this.getUserPostForTree(val.companyLabel[val.companyLabel.length - 1], val.index)
      }
    },
    // 修改分公司
    changeCompany (val) {
      if (val.companyLabel) {
        val.dutyUser = ''
        this.getUserPostForTree(val.companyLabel, val.index)
      }
    },
    // 修改园校
    changeSchool (val) {
      console.log(val, val.companyLabel[val.companyLabel.length - 1], val.index);
      if (val.companyLabel) {
        val.dutyUser = ''
        this.getUserPostForTree(val.companyLabel[val.companyLabel.length - 1], val.index)
      }
    },
    // 修改岗位
    changeUserPost (val) {
      // if(val.dutyUser.length == 2){
      // val.userPostList.forEach(e=>{
      // if(e.label == val.dutyUser[0]){
      //   e.child.forEach(ele=>{
      //     if(ele.label == val.dutyUser[1]){
      //       val.dutyUserName = ele.name
      //     }
      //   })
      // }
      // })
      // }
      // console.log(val)
      var platList = this.platFn(val.userPostList)
      let result = platList.filter(item => val.dutyUser.some(e => e[1] === item.label));
      val.dutyUserName = []
      const labelList = [];
      result.map(item => {
        if (!labelList.includes(item.label)) {
          labelList.push(item.label);
          val.dutyUserName.push(item.name)
        }
      })
    },
    platFn (list) {
      let res = []
      list.map(item => {
        if (item.child instanceof Array && item.child.length > 0) {
          res = res.concat(...item.child)
        }
      })
      return res
    },
    queryDict () {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },
    editList (type, item) {
      if (item.companyLabel == '') {
        this.$message.error('请选择分解对象')
        return
      }
      if (parseInt(item.targetValue) > parseInt(this.form.targetValue)) {
        this.$message.error('单个分解对象的目标值必须小于总目标')
        return
      }
      // if (item.targetValue == '' || item.targetValue <= 0) {
      //   this.$message.error('目标值不能为空且必须大于0')
      //   return
      // }
      if (item.targetValue == '') {
        this.$message.error('目标值不能为空')
        return
      }
      if (item.dutyUser == '') {
        this.$message.error('请选择指定岗位/人')
        return
      }
      if (type == 1) {
        item.isSave = 1
      } else if (type == 2) {
        item.isSave = 0
      }
    },
    delList (index) {
      this.childList.splice(index, 1)
    },
    getTree () {
      this.$api.getSchoolForTree().then(res => {
        if (res.data.code == '0') {
          // 
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getDecomposeObjectList () {
      this.$api.getDecomposeObjectList().then((res) => {
        if (res.data.code == 0) {
          this.headquartersObjList = res.data.data
          if (this.headquartersObjList) {
            this.schoolLength = 0
            this.headquartersObjList.forEach(e => {
              if (e.child) {
                this.schoolLength += e.child.length
              }
            })
            // console.log(this.schoolLength,this.schoolLength!=92)
          }
        }
      });
    },
    getCompanyDept () {
      this.$api.getCompanyDept({
        type: 'NODE_ORG'
      }).then((res) => {
        if (res.data.code == 0) {
          this.companyList = res.data.data
        }
      });
    },
    goBack () {
      this.$router.go(-1);
    },
    // 获取责任部门
    getDepartList () {
      this.$api.getDepartList().then(res => {
        if (res.data.code == '0') {
          this.deptLabelList = res.data.data
          if (this.deptLabelList) {
            this.deptLabelList.forEach((e, index) => {
              e.disabled = true
              e.label = index
            })
          }
          // console.log(this.deptLabelList)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取岗位/人
    getUserPostForTree (label, index) {
      this.userLoading = true
      this.$api.getUserPostForTree({
        label: label
      }).then(res => {
        this.userLoading = false
        if (res.data.code == '0') {
          this.childList[index].userPostList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.userLoading = false
      })
    },
    changeDimension (val) {
      console.log(val)
      if (val == 1) {
        // 总部
      } else if (val == 2) {
        // 分公司
        this.getCompanyDept()
      } else if (val == 3) {
        // 园校
        this.getDecomposeObjectList()
      }
      if (this.childList) {
        this.childList.forEach(e => {
          e.userPostList = []
          e.companyLabel = ''
        })
      }
    },
    changeTargetType (val) {
      console.log(val)
      if (val == 1 || val == 6) {
        this.form.type = '3'
        this.getDecomposeObjectList()
      }
    },
    getManageInfo (id) {
      let params = {
        id: id || this.id,
      };
      // console.log(params);
      this.$api.getFileInfo(params).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          this.form = res.data.data;
          this.edit = true;
          this.type = this.form.type;
          this.form.authority = this.form.authority.split(",");
          if (this.form.dataAttachmentList) {
            this.uploadList = this.form.dataAttachmentList;
            this.uploadList.forEach((item) => {
              item.name = item.attachmentName;
            });
          }
        } else {
          this.$message.error("该数据不存在");
          this.goBack();
        }
      });
    },
    createTargetType () {
      ///创建目标类型
      this.$prompt("目标类型：", "新增目标类型", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9]{1,100}$/,
        inputErrorMessage: "目标类型格式不正确，长度为1-100之间的有效字符",
        inputPlaceholder: '请输入目标类型'
      })
        .then(({ value }) => {
          let params = {
            type: "target_type",
            name: value,
            description: "目标类型"
          }
          this.$api.saveTargetDict(params).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "新增目标类型: " + value,
              });
              this.queryDict()
            } else {
              this.$message({
                type: "error",
                message: res.data.msg,
              });
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "取消输入",
          // });
        });
    },
    saveAndPublishTarget (formName) {
      // console.log(this.form.targetValue<this.unassigned)
      // if(this.form.targetValue<this.unassigned || this.unassigned<0){
      //   this.$message.error('分解值大于目标值，请重新分配')
      //   return
      // }
      console.log(this.unassigned < parseInt(this.form.targetValue), this.unassigned, parseInt(this.form.targetValue));
      if (this.unassigned != 0) {
        this.$message.error('总目标值不等于分解之和')
        return
      }
      console.log(this.childList)
      for (let index = 0; index < this.childList.length; index++) {
        const e = this.childList[index];
        if (e.isSave) {
          if (!e.targetValue) {
            this.$message.error('请输入目标值')
            return
          }
          if (!e.companyLabel) {
            this.$message.error('请选择分解对象')
            return
          }
          if (!e.dutyUser) {
            this.$message.error('请选择指定岗位/人')
            return
          }
        }
      }
      let arr = []
      console.log(this.childList, this.form.type)
      let childLength = 0
      // form.type 分解类型
      if (this.form.type == 3 && this.childList.length > 0) {
        // 园校分解
        console.log(this.childList, 'iii');
        let parent = []
        this.childList.map((e) => {
          if (e.isSave) {
            childLength += 1
            parent.push(e.companyLabel[0])
          }
        })

        parent = [...new Set(parent)]
        parent.forEach(ele => {
          let child = []
          let targetValue = 0
          this.childList.forEach(e => {
            if (e.isSave) {
              if (e.companyLabel[0] == ele) {
                targetValue += parseInt(e.targetValue)
                let userObj = []
                let postList = []
                postList = e.dutyUser.map(ep => ep[0])
                postList = [...new Set(postList)]
                postList.map(elep => {
                  let userLabel = []
                  e.dutyUser.map(ed => {
                    if (elep == ed[0]) {
                      userLabel.push(ed[1])
                    }
                  })
                  userObj.push({
                    postLabel: elep,
                    userLabel: userLabel,
                  })
                })
                console.log(userObj, postList);
                child.push({
                  level: 3,
                  type: this.form.type,
                  dutyUserArr: userObj,
                  jobArr: postList,
                  targetValue: e.targetValue,
                  companyLabel: e.companyLabel[0],
                  campusLabel: e.companyLabel[1],
                  dutyUserName: e.dutyUserName.join('、')
                })
              }
            }
          })
          arr.push({
            level: 2,
            type: this.form.type,
            targetValue: targetValue,
            childList: child,
            companyLabel: ele,
          })
        })
        // console.log(parent)
      } else if (this.form.type == 2 && this.childList.length > 0) {
        // 分公司分解
        this.childList.forEach(e => {
          if (e.isSave) {
            childLength += 1
            // let chooseChild = e.dutyUser[1] ? {} : e.userPostList.find((info) => { return info.label == e.dutyUser[0] })
            // let ids = []
            // console.log('777',chooseChild);
            // if(chooseChild.child){
            //   chooseChild.child.map((childInfo) => {
            //     ids.push(childInfo.label)
            //   })
            // }
            let dutyUser = []
            let userObj = []
            let postList = []
            postList = e.dutyUser.map(ep => ep[0])
            postList = [...new Set(postList)]
            postList.map(elep => {
              let userLabel = []
              e.dutyUser.map(ed => {
                if (elep == ed[0]) {
                  userLabel.push(ed[1])
                }
              })
              userObj.push({
                postLabel: elep,
                userLabel: userLabel,
              })
            })
            arr.push({
              level: 2,
              type: this.form.type,
              targetValue: e.targetValue,
              dutyUserArr: userObj,
              jobArr: postList,
              companyLabel: e.companyLabel,
              dutyUserName: e.dutyUserName.join('、')
            })
          }
        })
      } else if (this.form.type == 1 && this.childList.length > 0) {
        // 总部分解
        this.childList.forEach(e => {
          if (e.isSave) {
            childLength += 1
            let companyLabel
            if (e.companyLabel.length == 2) {
              companyLabel = e.companyLabel[1]
            } else if (e.companyLabel.length == 3) {
              companyLabel = e.companyLabel[2]
            }
            // let chooseChild = e.dutyUser[1] ? {} : e.userPostList.find((info) => { return info.label == e.dutyUser[0] })
            // let ids = []
            // if(chooseChild.child){
            //   chooseChild.child.map((childInfo) => {
            //     ids.push(childInfo.label)
            //   })
            // }
            let userObj = []
            let postList = []
            postList = e.dutyUser.map(ep => ep[0])
            postList = [...new Set(postList)]
            postList.map(elep => {
              let userLabel = []
              e.dutyUser.map(ed => {
                if (elep == ed[0]) {
                  userLabel.push(ed[1])
                }
              })
              userObj.push({
                postLabel: elep,
                userLabel: userLabel,
              })
            })
            arr.push({
              level: 2,
              type: this.form.type,
              targetValue: e.targetValue,
              dutyUserArr: userObj,
              jobArr: postList,
              companyLabel: companyLabel,
              dutyUserName: e.dutyUserName.join('、')
            })
          }
        })
      }
      if (childLength == 0 && this.childList.length > 0) {
        this.$message.error('请保存分解数据')
        return
      }
      console.log('aaaaaaa', arr);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let parmas = JSON.parse(JSON.stringify(this.form));
          // let childList = JSON.parse(JSON.stringify(this.childList))
          parmas.childList = arr
          console.log(typeof parmas.deptLabel == 'string')
          if (typeof parmas.deptLabel == 'string') {
            parmas.deptLabel = parmas.deptLabel
          } else {
            parmas.deptLabel = parmas.deptLabel[parmas.deptLabel.length - 1]
          }
          // console.log(childList)
          if (parmas.childList.length == 0) {
            this.$message.error('请添加分解数据')
            return
          }
          parmas.iconPath = this.form.iconPath.id
          this.uploading = true
          this.$api.saveTarget(parmas).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "保存成功",
              });
              this.uploading = false
              this.goBack();
            } else {
              this.uploading = false
              this.$message.error(res.data.msg || "保存失败");
            }
          });
        }
      });
    },
    getInputChange (val, type, index, item) {
      let resultValue = ''
      let info = { ...item }
      resultValue = val.replace(/[^\w\.\/]/ig, '')
      // if(resultValue>this.unassigned){
      //   resultValue = this.unassigned
      // }
      info[type] = resultValue
      this.$set(this.childList, index, info)
    },
    limitTargetValue (val, val2) {
      console.log(val, val2)
      if (val > this.unassigned) {
        // val = unassigned
        val2.targetValue = this.unassigned
        this.$forceUpdate()
      }
    },
    uniqueJsonArray (paylist) {
      var payArr = [];
      for (var i = 1; i < paylist.length; i++) {
        var payItem = paylist[i];
        var repeat = false;
        for (var j = 0; j < payArr.length; j++) {
          if (payItem.companyLabel[0] == payArr[j].companyLabel[0]) {
            repeat = true;
            break;
          }
        }
        if (!repeat) {
          payArr.push(payItem);
        }
      }
      return payArr;
    },
    initIconData () {
      this.$api.getAllIconList().then(res => {
        if (res.data.code == '0') {
          this.iconPathList = res.data.data
          // console.log(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    chooseIconPath (item) {
      this.iconPathInfo = item
    },
    chooseIconPathBtn () {
      this.form.iconPath = this.iconPathInfo
      this.$refs.ruleForm.clearValidate('iconPath')
      this.dialogVisible = false
    },
    showIcon () {
      if (this.form.iconPath) {
        this.iconPathInfo = this.form.iconPath
      } else {
        this.iconPathInfo = ''
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
}
table tr th {
  padding: 10px 0;
}
table,
table tr th,
table tr td {
  border: 1px solid #ccc;
}
table {
  width: 100%;
  min-height: 25px;
  line-height: 25px;
  text-align: center;
  border-collapse: collapse;
  padding: 2px;
}
table tr td {
  padding: 5px;
}
.iconTitle {
  display: flex;
  justify-content: space-between;
  padding: 2px 28px 14px;
  align-items: center;
  color: #3d3e4f;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .el-icon-close {
    color: #909399;
    font-size: 14px;
    cursor: pointer;
  }
}
.iconPathWrap {
  width: 50px;
  height: 50px;
  background: #eaf1fc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}
/deep/.el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
/deep/.el-dialog__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.dialog-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.dialogDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 18px;
  box-sizing: border-box;
  .iconPathWrap {
    background: #fff;
    border: 1px solid #fff;
    cursor: pointer;
  }
  .active {
    border: 1px solid #003685;
  }
}
.icon-list {
  display: flex;
  flex-wrap: wrap;
  .icon-item {
    margin-right: 6px;
  }
}
.dialogDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 18px;
  box-sizing: border-box;
}
</style>
<style>
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
.opIocn {
  padding: 10px 0;
  z-index: 1900 !important;
}
</style>